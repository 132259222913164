<template>
  <router-view></router-view>
  <van-tabbar v-if="showTabbar" route active-color="#e63932" inactive-color="#a0a0a0">
    <van-tabbar-item replace to="/index" icon="good-job">点赞</van-tabbar-item>
    <van-tabbar-item replace to="/rank" icon="medal">排行榜</van-tabbar-item>
    <van-tabbar-item replace to="/intro" icon="label">介绍</van-tabbar-item>
  </van-tabbar>
</template>

<script>

import {ref} from "vue";
import router from "@/router/index";
import Bottom from "@/components/bottom";

export default {
  name: 'App',
  components: {Bottom},
  setup(){
    const showTabbar = ref(false)

    router.beforeEach((to,from,next)=>{
      let path = to.path.substr(1)
      if(('login,detail,judge,history,404').indexOf(path)==-1){
        showTabbar.value = true
      }else{
        showTabbar.value = false
      }
      next()
    })

    showTabbar.value = localStorage.getItem('showTabbar')
    return {
      showTabbar
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
