

//正式环境
export const BASE_URL = 'https://wx.occamedu.com/vote/' //正式
export const LOGIN_URL = BASE_URL+'wx/url'
export const FRONT_URL = 'https://vote.occamedu.com/'
export const IMG = BASE_URL + "file/genUrl?fileName="

//开发环境
// export const BASE_URL = 'https://frp.ucool.tech/' //测试
// export const LOGIN_URL = BASE_URL+'wx/url'
// export const IMG = "https://wx.occamedu.com/vote/file/genUrl?fileName="
