import { createApp } from "vue";
import App from "./App.vue";

// 导航栏
import { Tabbar, TabbarItem } from "vant";

import router from "@/router/index";
// 拦截器
import '@/router/permission'

const app = createApp(App);
app.use(router).use(Tabbar).use(TabbarItem).mount("#app");
