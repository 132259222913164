<template>
 <a style="color: #666666;font-size:10px;padding: 20px;margin-bottom: 20px;display: block;text-decoration: none" href="https://mp.weixin.qq.com/s/9SFit3bQ4RpY325wC-8A3A">© 2022 OCCAM Inc.All right reserved.</a>
</template>

<script>
export default {
  name: "bottom"
}
</script>

<style scoped>

</style>