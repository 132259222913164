import router from './index'
import {BASE_URL} from '@/utils/CONST'

const allowList = ['login','follow','/follow','index','/index']
router.beforeEach((to, from, next) => {
    // 存在token时
    if (localStorage.getItem('token')) {
        next()
    } else {
        if (allowList.includes(to.name)||allowList.includes(to.path)) {
            // 在免登录名单，直接进入
            next()
        } else {
            router.push("/follow")
            // window.location.href = BASE_URL+"wx/url"
            // if(window.location.hash.indexOf("index?token")!=-1){
            //     next()
            // }else{
            //     window.location.href = BASE_URL+"wx/url"
            // }
        }
    }
})

