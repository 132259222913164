import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path:'/',
        redirect:'/index',
    },
    {
        path:'/index',
        component: () => import("../view/index")
    },
    {
        path:'/intro',
        component: () => import("../view/intro")
    },
    {
        path:'/rank',
        component: () => import("../view/rank")
    },
    {
        path:'/detail',
        component: () => import("../view/detail")
    },
    {
        path:'/login',
        component: () => import("../view/admin/login")
    },
    {
        path:'/judge',
        component: () => import("../view/admin/judge")
    },
    {
        path:'/history',
        component: () => import("../view/history")
    },
    {
        path:'/test',
        component: () => import("../view/test")
    },
    {
        path:'/follow',
        component: () => import("../view/follow")
    },
    {
        path: '/:pathMatch(.*)',
        component: () => import("../view/404")
    }
]

routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
